const config = {
	host: '//www.aqhyb.com', //接口域名
	imgRoot: 'https://huiyoubangoss.oss-cn-hangzhou.aliyuncs.com', //图片前缀
	md5: {
		master: 'uiw2kis881usjeh',
		service: 'uiw2kis8ki9sjeh',
		manufacturer: 'uiw2kis82husjeh'
	}
};
if (process.env.NODE_ENV === 'development') {
	//开发环境
	config.host = '/dev-api'
} else if (process.env.NODE_ENV === 'production') {
	//生产环境
} else if (process.env.NODE_ENV === 'test') {
	//测试环境
	config.host = 'http://172.18.1.90:9301'
}
export default config


/* 本地
	config.host = '/dev-api'
 */

/* 本地ip
	config.host = 'http://172.18.1.90:9301'
 */

/* 测试
	config.host = 'http://a.hnrj.hnvtcloud.cn:8301'
 */

/* 线上
	config.host = 'https://www.aqhyb.com'
 */

/* 
	小李 15155115323 安徽省合肥市蜀山区科大讯飞3号楼
 */

